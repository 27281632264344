import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import React from "react";

import {
  OldHeadingUseTextInstead,
  OldSubheadingUseTextInstead,
} from "~src/designSystem/deprecated/Heading";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { IconText } from "~src/designSystem/molecules/IconText";
import { Head } from "~src/designSystem/sortLater/Head";
import { TABLET_MAX } from "~src/shared/styles/responsive";
import { themeDark } from "~src/shared/theme/darkVariant";
import { VideoWithFallbacks } from "~src/vendor/changelog/components/VideoWithFallbacks";

const NotFoundPage: React.FC = () => {
  const router = useRouter();
  return (
    <ThemeProvider theme={themeDark}>
      <WrapperContainer gridTemplateColumns="auto auto">
        <Head title="Page Not Found | Pipe" />
        <Flexbox justifyContent="center" alignItems="center" pad width="600px">
          <Flexbox
            justifyContent="center"
            // alignItems="center"
            maxWidth="400px"
            pad
          >
            {/* // TODO (ble): update deafult heading color to inherit */}
            <OldHeadingUseTextInstead size="36px" bold>
              Uh oh...
            </OldHeadingUseTextInstead>
            <OldSubheadingUseTextInstead size="16px" oldMarginBottom>
              It seems like someone tripped over a cable... or the page you’re looking for doesn’t
              exist.
            </OldSubheadingUseTextInstead>
            <a onClick={() => router.back()}>
              <IconText iconName="arrow-left" iconSize={10}>
                Back
              </IconText>
            </a>
          </Flexbox>
        </Flexbox>
        <Graphic justifyContent="center" alignItems="center">
          <Video
            webm="/static/assets/animations/404/404-error.webm"
            mp4="/static/assets/animations/404/404-error.mp4"
            gif="/static/assets/animations/404/404-error.jpg"
            noLoop
          />
        </Graphic>
      </WrapperContainer>
    </ThemeProvider>
  );
};

export default NotFoundPage;

const Graphic = styled(Flexbox)`
  order: 1;
  max-height: 100vh;
  @media (max-width: ${TABLET_MAX}) {
    background-color: transparent;
  }
`;

const WrapperContainer = styled(Gridbox)`
  height: 100vh;
  background: #000000;
  @media (max-width: ${TABLET_MAX}) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

const Video = styled(VideoWithFallbacks)`
  max-height: 80%;
`;
